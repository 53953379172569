<template>
  <div>
    <!-- scoring status -->
    <el-card class="scoring">
      <el-row>
        <el-col :span="5">
          <p>Scoring status</p>
        </el-col>
        <el-col :span="12">
          <p>Evaluation content</p>
        </el-col>
        <el-col :span="7">
          <p>Commodity information</p>
        </el-col>
      </el-row>
    </el-card>
    <!-- content -->
    <el-card class="content">
      <el-row>
        <el-col :span="18">
          <p>Order No:121566166614</p>
        </el-col>
        <el-col :span="3">
          <p>Buyer's name</p>
        </el-col>
        <el-col :span="3">
          <img src="@/assets/Frame_1.png" />
          <p class="contact">Contact me</p>
          <div class="clear"></div>
        </el-col>
      </el-row>
    </el-card>
    <el-card class="card">
      <el-row>
        <el-col :span="6" class="p1">
          <p>Ratings 4.0</p>
          <p class="mt1">Credit score + 1</p>
        </el-col>
        <el-col :span="10" class="p1">
          <p>cute lounge shorts my boyfriend loved them perfect booty shorts</p>
          <p class="mt1">like for points</p>
          <p class="mt1 p2">22 Aug,2021 10：00：25</p>
        </el-col>
        <el-col :span="8" class="p3 mt1">
          <p>Early autumn small fresh and sweet temperament</p>
          <p class="mt1">French shirt female ...</p>
          <p class="mt p4">$40</p>
        </el-col>
      </el-row>
    </el-card>

    <el-card class="content">
      <el-row>
        <el-col :span="18">
          <p>Order No:121566166614</p>
        </el-col>
        <el-col :span="3">
          <p>Buyer's name</p>
        </el-col>
        <el-col :span="3">
          <img src="@/assets/Frame_1.png" />
          <p class="contact">Contact me</p>
          <div class="clear"></div>
        </el-col>
      </el-row>
    </el-card>
    <el-card class="card">
      <el-row>
        <el-col :span="6" class="p1">
          <p>Ratings 4.0</p>
          <p class="mt1">Credit score + 1</p>
        </el-col>
        <el-col :span="10" class="p1">
          <p>cute lounge shorts my boyfriend loved them perfect booty shorts</p>
          <p class="mt1">like for points</p>
          <p class="mt1 p2">22 Aug,2021 10：00：25</p>
        </el-col>
        <el-col :span="8" class="p3 mt1">
          <p>Early autumn small fresh and sweet temperament</p>
          <p class="mt1">French shirt female ...</p>
          <p class="mt p4">$40</p>
        </el-col>
      </el-row>
    </el-card>
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
.scoring {
  background: #ffdcbc;
  font-size: 13px;
  line-height: 15px;
  font-weight: 700;
}
.content {
  margin-top: 15px;
  background: #fae1e0;
}
.content p {
  font-size: 13px;
  line-height: 15px;
  margin-top: 5px;
  font-weight: 700;
}
.content img {
  float: left;
  width: 25px;
  height: 25px;
}
.content .contact {
  font-size: 13px;
  line-height: 15px;
  margin-top: 5px;
  margin-left: 5px;
  font-weight: 700;
  float: left;
}

.card .p1 {
  font-size: 13px;
  margin-top: 10px;
  font-weight: 700;
}
.card .p2 {
  font-size: 10px;
  color: gray;
}
.card .p3 {
  font-size: 13px;
  font-weight: 700;
  color: #ff8630;
}
.card .p4 {
  font-size: 13px;
  color: #ff0a0a;
}
</style>